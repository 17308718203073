<script>
import ApiService from "@/services/api.service";
import {debounce} from "lodash";

export default {
  name: "PaginationWrapper",
  data() {
    return {
      items: [],
      page: 1,
      totalItems: 0,
      totalPages:0,
      loading: false,
      search: "",
      debouncedLoadPage: null,

    };
  },
  props: {
    filter: {
      type: Object,
      default: null
    },
    sort:{
      type: Object,
      default() {
        return {
          sortBy: "firstName",
          order: "DESC"
        };
      }
    },
    pageSize: {
      type: Number,
      default: 10
    },
    url: {
      type: String,
      required: true
    }
  },
  computed:{
    filterC:{
      get(){
        return this.filter;
      },
      set(value){
        this.$emit("update:filter", value);
      }
    }
  },
  methods:{
    async loadPage(page = 1) {
      this.loading = true;
      if (!page) page = 1;
      try {
        console.log("Loading page", this.url + (page - 1));
        console.log("Filter", {filter:this.filterC, sort:this.sort?.value});
        let filterWithSort = {filter:this.filterC, sort:this.sort?.value};
        const response = await ApiService.post(this.url + (page - 1), filterWithSort);
        const data = response.data;
        this.items = data.content;
        this.totalItems = data.totalElements;
        this.page = data.number + 1;
        this.totalPages=data.totalPages;
      } catch (error) {
        console.error("Failed to load users:", error);
      } finally {
        this.loading = false;
      }
    },
    onPageChange(newPage) {
      this.loadPage(newPage);
      window.scrollTo(0, 0);
    }
  },
  created() {
    this.debouncedLoadPage = debounce((page) => this.loadPage(page), 300);
    this.loadPage();
  },
  watch: {
    sort: {
      handler() {
        console.log("Sort changed", this.sort);
        this.debouncedLoadPage(1);
      },
      deep: true
    },
    filter: {
      handler() {
        this.debouncedLoadPage(1);
      },
      deep: true
    }
  }
}
</script>

<template>
  <div>
  <slot name="filter" :items="items" :filter="filterC" :totalItems="totalItems">
    <v-text-field append-inner-icon="mdi-magnify" v-model="search" :label="$t('form.search')+'...'" />
  </slot>
    <slot name="loading" :loading="loading">
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    </slot>
  <slot :items="items" :loading="loading">
    <v-row>
      <p v-if="!loading && items.length<=0">Keine Objekte gefunden</p>
      <v-col v-for="item of items" :key="item.id" cols="12" md="6">
        <slot :item="item" name="item"/>
      </v-col>
    </v-row>
  </slot>
  <v-pagination
      v-model="page"
      :length="totalPages"
      @update:modelValue="onPageChange"
  ></v-pagination>
  </div>
</template>

<style scoped>

</style>