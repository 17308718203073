<template>
  <div class="">
    <v-container
        style="min-height: 20vh"
        class="d-flex flex-column justify-center"
    >
      <h1 style="" class="text-left ml-3 text-h2">
        <span class="text-center">My Timetable</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn size="small" variant="outlined" class="ml-2" icon @click="changeView" v-bind="props">
              <v-icon>{{ view === 'timetable' ? 'mdi-view-list' : 'mdi-calendar' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ view === 'timetable' ? 'Switch to List View' : 'Switch to Timetable View' }}</span>
        </v-tooltip>
      </h1>
    </v-container>
    </div> <v-container>

  <v-sheet v-if="view!='timetable'" color="transparent" min-height="800px" class="bg-transparent">
      <v-list color="transparent" class="bg-transparent">
        <div v-if="loading">
          <div class="mb-5" v-for="n in 5" :key="n">
            <v-card
              flat
              class="my-2 pa-8 custom-border-radius"
            >
              <v-row>
                <v-col cols="2" class="hidden-sm-and-down">
                  <v-skeleton-loader
                    class="rounded-lg"
                    color="transparent"
                    width="80%"
                    height="80%"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="10" class="pt-1">
                  <v-skeleton-loader
                    class="mt-2"
                    type="heading"
                    color="transparent"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="mt-2"
                    type="text"
                    color="transparent"
                  ></v-skeleton-loader>
                  <div
                    class="d-flex ga-1 justify-space-between flex-wrap ml-3 mt-5"
                  >
                    <div class="d-flex ga-2 flex-wrap">
                      <v-skeleton-loader
                        class="mr-2"
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        class="mr-2"
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                    </div>
                    <v-skeleton-loader
                      width="100"
                      type="chip"
                      color="transparent"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>
        <div
          class="d-flex justify-center flex-wrap"
          v-else-if="emptyFavourites && !currentUser"
        >
          <h3 style="width: 100%" class="text-center">
            Login to add favourites and build your personal timetable
          </h3>
          <v-btn class="my-3" @click="$router.push('/login')">Login</v-btn>
        </div>
        <div
          class="d-flex justify-center flex-column text-center flex-wrap"
          v-else-if="emptyFavourites"
        >
          <h2 style="width: 100%" class="text-center">
            Looks empty
          </h2>
          <p>Add some Events to build you personal timeline.</p>
          <v-btn class="mx-auto mt-1 text-center" href="/events" variant="plain"
            >Take me there</v-btn
          >
        </div>
        <div class="" v-else>
          <v-container
              class=""
              v-for="day of eventsWithDays"
              :key="day.day"
          >
            <h3
                class="text-uppercase font-weight-regular text-color-accent mt-2"
            >
              {{ dateToday(day.day) }}
            </h3>

            <div class="py-2" v-for="event of day.events" :key="event.id">
              <EventItem
                  :event="event.event"
                  :artists="event.artists"
                  @removeFromFavourites="

                        this.removeFavourite;
                  "
              />
            </div>
          </v-container>
        </div>
      </v-list>
    </v-sheet>
    <TimeTableWrapper :venues="venues" :events-with-days="eventsWithDays" v-else/>

  </v-container>
</template>

<script>
import {getFavourites, getSpecificEvents} from "@/services/event.service";
import EventItem from "@/views/Event/EventItem.vue";
import ViewHeadline from "@/components/helpers/ViewHeadline";
import TimeTableWrapper from "@/components/timetable/TimeTableWrapper.vue";
import moment from "moment/moment";
export default {
  name: "FavouritesView",
  components: {TimeTableWrapper, EventItem, ViewHeadline },
  data() {
    return {
      loading: false,
      view:"timetable",
    };
  },
  created() {
    if (this.currentUser) {
      this.getFavourites();
    }
  },
  methods: {
    changeView(){
      this.view = this.view === "timetable" ? "list" : "timetable";
    },
    dateToday(date) {
      let moment = require("moment");
      return moment(date).format("DD[.]MM dddd");
    },
    async getFavourites() {
      this.loading = true;
      let response = await getFavourites(this.currentUser.favourites);
      this.$store.dispatch("events/updateFavourites", response);
      this.loading = false;
    },
    removeFavourite(id) {
      this.eventsWithDays = this.eventsWithDays.map((day) => {
        day.events = day.events.filter((event) => event.event.id !== id);
        return day;
      });
    },
  },
  computed: {
    venues: {
      get() {
        let venues = this.eventsWithDays.map((day) => {
          return day.events.map((event) => event.event.venue);
        }).flat();

        let uniqueVenues = [...new Set(venues.map(venue => venue.id))]
            .map(id => venues.find(venue => venue.id === id));

        return uniqueVenues;
      },
    },
    emptyFavourites() {
      for(let day of this.eventsWithDays){
        if(day.events.length > 0){
          return false;
        }
      }
      return true;
    },
    eventsWithDays:{
      get(){
        return this.$store.state.events.favourites;
      },
      set(value){
        this.$store.dispatch("events/updateFavourites", value);
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style scoped></style>
