
const success= {
    state:null,
    animateOut:false,
};
const loadingState=false;
const initialState = {
    loadingState,
    success,
    loginCognitoProcess:{
        isLoading:false,
        isNeedingExtraEmail:false,
        tokenObject:{
            token:null,
            extraEmail:null
        }
    }
}

export const loading = {
    namespaced: true,
    state: initialState,
    actions: {
        startCognitoProcess({commit}){
            commit("setCognitoProcess",true)
        },
        checkNeedingExtraEmail({commit},token){
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            let object = JSON.parse(jsonPayload);
            console.log(object);
            if(!object.email) {
                commit("setNeedingExtraEmail",true);
            }
            else{
                commit("setNeedingExtraEmail",false);
            }
        },
        stopCognitoProcess({commit}){
          commit("setCognitoProcess",false)
        },

        toggle({ commit }, loadingState) {
            commit("toggle",loadingState)
        },
        success({commit}){
            commit("success")
        },
        error({commit}){
            commit("error")
        }

    },

    mutations: {

        setNeedingExtraEmail(state,isNeedingExtraEmail){
            state.loginCognitoProcess.isNeedingExtraEmail=isNeedingExtraEmail;
        },

        setCognitoProcess(state, processState){
            state.loginCognitoProcess.isLoading=processState;
        },
        toggle(state, loadingState){
            state.loadingState=loadingState;
        },
        success(state){
            state.success.state="success"
            setTimeout(() => {state.success.animateOut=true;
                setTimeout(()=>{state.success.state=null;
                    state.success.animateOut=false;
                    state.loadingState=false;
                },1000)

            },2000);

        },
        error(state){
            state.success.state="error"
            setTimeout(() => {state.success.animateOut=true;
                setTimeout(()=>{state.success.state=null;
                    state.success.animateOut=false;
                    state.loadingState=false;
                },1000)

            },2000);
        }




    }
};