<script>
import moment from "moment/moment";

export default {
  name: "ProgramFilter",
  data(){
    return{
      show:false,
      loading:false,
    }
  },
  watch:{
    filter:{
      handler(){
        this.update();
      },
      deep:true
    }
  },
  methods:{
    removeFilter(){
      this.filter = {
        selectedGenres: [],
        selectedDates: [],
        selectedTypes:[],
        selectedConferenceTypes: [],
        selectedConferenceTopics: [],
      };
    },
    weekday(day) {
      let moment = require("moment");
      return moment(day).format("dddd");
    },
    async update() {
      this.loading = true;
      this.$emit("loading", true);

      await Promise.all([
        this.$store.dispatch("programStore/loadEventsGroupedByDay", this.filter),
        this.$store.dispatch("programStore/loadVenuesFiltered", this.filter),
        this.$store.dispatch("programStore/loadArtistsFiltered", { page: 0, filter: this.filter })
      ]);

      this.$emit("loading", false);
      this.loading = false;
    }
  },
  computed:{
    filterIsSet(){
      return this.filter.selectedGenres.length > 0 || this.filter.selectedDates.length > 0 || this.filter.selectedTypes.length > 0 || this.filter.selectedConferenceTypes.length > 0 || this.filter.selectedConferenceTopics.length > 0;
    },
    totalArtists(){
      return this.$store.state.programStore.artists.totalElements;

    },
    totalEvents(){
      let events = this.$store.state.programStore.events.map(e => e.events).flat();
      return events.length;
    },
    filter:{
      get(){
        return this.$store.state.programStore.filter;
      },
      set(value){
        this.$store.dispatch("programStore/setFilter",value);
      }
    },
    filterValues(){
      return this.$store.state.programStore.filterValues;
    },
  }
}
</script>

<template>
  <v-container class="py-5">
    <v-row class="">
      <v-col cols="10">
        <v-chip-group
            color="white"
            filter
            multiple
            mandatory
            v-model="filter.selectedTypes"
        >
          <v-chip
              rounded="lg"
              variant="tonal"
              value="conference"
              class="filter-chip"
              size="x-large"
          >Conference</v-chip
          >
          <v-chip
              rounded="lg"
              variant="tonal"
              value="festival"
              class="filter-chip"
              size="x-large"
          >Festival</v-chip
          >
        </v-chip-group>
      </v-col>

      <v-col cols="2" class="d-flex align-center justify-end">
        <v-btn
            v-if="$vuetify.display.mdAndUp"
            variant="flat"
            rounded="lg"
            color="white"
            :class="{ 'filter-chip-active': filter.selectedGenres?.length > 0 }"
            @click="show = !show"
            size="large"
            prepend-icon="mdi-tune-vertical"
        >
          <span>Filter</span>
        </v-btn>
        <v-btn
            v-else
            density="comfortable"
            color="white"
            :class="{ 'filter-chip-active': filter.selectedGenres?.length > 0 }"
            @click="show = !show"
            size="large"
            icon="mdi-tune-vertical"
        >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog max-width="400px" v-model="show">
    <v-card :loading="loading" class="pa-4" color="primary">
      <v-btn position="absolute" style="right:0;top:0" variant="plain" size=x-large color="white" icon="mdi-close" @click="show=!show"></v-btn>
      <h2 class="text-center">Filter</h2>
      <h4 class="mb-5 text-center">Found <span v-text="totalEvents"></span> Events with <span v-text="totalArtists"></span> Artists <v-btn variant="text" v-if="filterIsSet" @click="removeFilter">remove all filters</v-btn> </h4>
      <div>
        <h3>Days</h3>
        <v-chip-group filter column multiple v-model="filter.selectedDates">
          <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="day of filterValues.allDates"
              :value="day"
              :key="day"
          >{{ weekday(day) }}</v-chip
          >
        </v-chip-group>
      </div>
      <div>
        <h3>Genres</h3>
        <v-chip-group filter column multiple v-model="filter.selectedGenres">
          <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="genre of filterValues.allGenres"
              :value="genre.id"
              :key="genre.id"
          >{{ genre.name }}</v-chip
          >
        </v-chip-group>
      </div>

      <div>
        <h3>Conference Type</h3>
        <v-chip-group filter column multiple v-model="filter.selectedConferenceTypes">
          <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="type of filterValues.allConferenceTypes.filter(t => t!=null)"
              :value="type"
              :key="type"
          ></v-chip
          >
        </v-chip-group>
      </div>
      <div>
        <h3>Conference Topic</h3>
        <v-chip-group filter column multiple v-model="filter.selectedConferenceTopics">
          <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="topic of filterValues.allConferenceTopics"
              :value="topic"
              :key="topic"
          >{{ topic }}</v-chip
          >
        </v-chip-group>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>