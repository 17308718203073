<template>
  <v-container
    style="min-height: 20vh"
    class="d-flex flex-column justify-center"
  >
    <h1 style="" class="text-left ml-3 text-h2">
      <span class="text-center">{{ headlineText }}</span>
    </h1>
  </v-container>
</template>

<script>
export default {
  props: {
    headlineText: {
      type: String,
      required: true,
    },
  },
  methods: {
    generateRepeatedText() {
      return (this.headlineText + " // ").repeat(20);
    },
  },
};
</script>

<style scoped>
.headline-container {
  width: 100%;
  overflow: hidden;
}

.scrolling-headline {
  white-space: nowrap;
  color: #ffffff24;
  font-family: "Neue Singular H", Sans-serif;
  font-size: 3rem;
  font-weight: 600;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
}

.d-md-none {
  display: block;
  width: 100%;
  text-align: center;
  white-space: normal;
}
</style>
