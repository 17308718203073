<template>
  <div v-if="event && !loading">
    <div style="position: relative" class="d-flex align-center">
      <v-container
          :class="{ 'iframe': iframe, 'mt-10' :!iframe }"
        class="d-flex flex-wrap flex-column justify-center align-start"
      >
        <v-btn v-if="iframe" class="mb-10" @click="$router.back()" variant="tonal" icon="mdi-arrow-left" v-tooltip="'Back to Events'">
        </v-btn>
        <p class="ml-1">
          <span
            style="font-size: 1.2rem; color: rgb(var(--v-theme-accentYellow))"
            v-text="type"
          ></span>
        </p>
        <h2
          class="text-left font-weight-regular"
          :style="{ fontSize: $vuetify.display.mdAndUp ? '2.5rem' : '2rem' }"
        >
          {{ event.event.title }}
        </h2>
        <h2>
          <span
            style="color: var(--v-accent-base); font-size: 1.2rem"
            class="text-uppercase text-truncate font-weight-thin mr-1"
            v-for="(genre, index) of event.event.genres"
            :key="genre.id"
            >{{ genre.name
            }}<span v-if="index < event.event.genres.length - 1">,</span>
          </span>
        </h2>
        <p>
          <span>w\</span
          ><span
            class="ml-1"
            v-for="(artist, index) of event.artists"
            :key="artist.id"
            >{{ artist.name
            }}<span v-if="index < event.artists.length - 1">,</span></span
          >
        </p>
        <div class="d-flex flex-wrap ga-3 mt-5">
          <v-chip
            variant="outlined"
            size="large"
            prepend-icon="mdi-map-marker"
            v-if="event.event.venue"
            >{{ event.event.venue.name }}</v-chip
          >
          <v-chip
            v-else
            variant="outlined"
            size="large"
            prepend-icon="mdi-map-marker"
            >TBA</v-chip
          >

          <v-chip variant="outlined" size="large" prepend-icon="mdi-calendar">{{
            date
          }}</v-chip>

          <v-chip variant="outlined" size="large" prepend-icon="mdi-clock">{{
            time
          }}</v-chip>
        </div>
      </v-container>
    </div>
    <!-- <v-spacer style="height: 150px" /> -->
    <v-container class="pb-10">
      <div v-text="event.event.description"></div>
      <div class="d-flex align-center">
        <div>
          <v-btn class="" icon
            ><v-icon size="x-large">mdi-instagram</v-icon></v-btn
          >
          <v-btn class="ml-5" icon
            ><v-icon size="x-large">mdi-facebook</v-icon></v-btn
          >
        </div>
        <v-btn
          class="ml-5"
          size="large"
          append-icon="mdi-arrow-top-right"
          color="accentYellow"
          v-if="event.event.ticketLink"
          :href="event.event.ticketLink"
          target="_blank"
          >Buy tickets</v-btn
        >
      </div>
    </v-container>
    <v-container v-if="event.artists.length>0">
      <h3 class="mt-5 my-2">Speakers & Artists on this:</h3>
      <ArtistList :iframe="iframe" :artists="event.artists" />
    </v-container>
  </div>
  <div v-if="loading" class="skeleton-wrapper">
    <div class="">
      <v-container
        min-height="50vh"
        class="d-flex flex-wrap flex-column justify-center align-start"
      >
        <v-skeleton-loader
          type="text"
          color="transparent"
          class="mt-5 mb-5"
          width="30%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="heading"
          color="transparent"
          class="mt-5 mb-5"
          width="70%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="text"
          color="transparent"
          class="mt-5 mb-5"
          width="30%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="text"
          color="transparent"
          class="mt-5 mb-5"
          width="30%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="text"
          color="transparent"
          class="mt-5 mb-5"
          width="30%"
        ></v-skeleton-loader>
      </v-container>
    </div>
    <v-spacer style="height: 200px" />
    <v-container class="pb-10">
      <v-skeleton-loader
        type="paragraph"
        class="mt-5"
        color="transparent"
      ></v-skeleton-loader>
      <div class="d-flex justify-space-between align-center mt-5">
        <v-skeleton-loader
          type="button"
          width="100px"
          color="transparent"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="button"
          width="150px"
          color="transparent"
        ></v-skeleton-loader>
      </div>
    </v-container>
    <v-container>
      <v-skeleton-loader
        type="heading"
        class="mb-5"
        width="30%"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="heading"
        class="mb-5"
        width="40%"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="card-avatar, actions"
        class="mb-5"
        color="transparent"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import { getEventWithArtistsById } from "@/services/event.service";
import ArtistList from "@/components/artist/ArtistList.vue";

export default {
  name: "SingleEventSite",
  components: {ArtistList,},
  props: {
    eventId: String,
  },

  computed: {
    iframe(){
      return this.$route.meta.iframe;
    },
    type() {
      if (this.event.event.conference) return "Conference";
      else return "Festival";
    },
    date() {
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("dddd MM[/]DD[/]YY");
    },
    time() {
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("LT");
    },
  },
  data() {
    return {
      event: null,
      loading: false,
    };
  },
  created() {
    if (this.eventId) this.getEvent();
  },
  methods: {
    getEvent: async function () {
      this.loading = true;
      this.event = await getEventWithArtistsById(this.eventId);
      this.loading = false;
      console.log(this.event);
    },
  },
};
</script>

<style scoped>
.iframe{
  margin-top:100px!important;
}
</style>
