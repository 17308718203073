<template>
  <v-app-bar
    app
    order="1"
    height="100"
    color="transparent"
    class=""
    flat
    id="topbar"
    width="100%"
  >
    <v-container
      style="width: 100%"
      class="mx-auto d-flex justify-space-between align-center"
    >
      <v-btn v-if="!$vuetify.display.md" icon @click="$router.back()">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <div style="height: 150px; width: 150px">
        <v-img
          height="150px"
          width="150px"
          :src="require('../../assets/bdme_logo_mit_schrift.png.webp')"
        ></v-img>
      </div>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down mr-lg-5">
        <v-btn
          class="menu-item"
          :disabled="$route.name === 'Feed'"
          text
          to="/feed"
          >Feed</v-btn
        >
        <v-btn
          class="menu-item"
          :disabled="$route.name === 'Lobby'"
          text
          to="/lobby"
          >Lobby</v-btn
        >
        <v-btn
          class="menu-item"
          :disabled="$route.name === 'Events'"
          text
          to="/events"
          >Program</v-btn
        >
        <v-btn
          class="menu-item"
          :disabled="$route.name === 'My Timetable'"
          text
          to="/favourites"
          >My Timetable</v-btn
        >
      </div>
      <!-- Tickets Knopf zu eigenem Shop -->
      <!-- <v-btn
        class="hidden-sm-and-down mr-lg-10"
        style="
          text-transform: none;
          font-size: 1.3rem;
          letter-spacing: 1px;
          font-weight: 400;
          font-family: 'Neue-Singular-H', sans-serif;
        "
        size="x-large"
        variant="flat"
        density="comfortable"
        color="accentYellow"
        to="/shop"
        >Tickets</v-btn
      > -->
      <!-- Tickets Knopf zu Paylogic -->
      <v-btn
        class="hidden-sm-and-down mr-lg-10"
        style="
          text-transform: none;
          font-size: 1.3rem;
          letter-spacing: 1px;
          font-weight: 400;
          font-family: 'Neue-Singular-H', sans-serif;
        "
        size="x-large"
        variant="flat"
        density="comfortable"
        color="accentYellow"
        href="https://shop.berlin-dance-music-event.com/0f38c67711884e5d9962a183138123a6/welcome-to-the-bdme25-ticketshop"
        target="_blank"
        >Tickets</v-btn
      >
      <!-- Warenkorb Knopf -->
      <!-- <CartButtom /> -->
      <div style="z-index: 10" class="mx-2 mx-lg-5">
        <v-btn
          :disabled="$route.name === 'Profile'"
          @click.prevent="$router.push('/profile')"
          v-if="!currentUser"
          icon
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn
          :disabled="$route.name === 'Profile'"
          @click.prevent="$router.push('/profile')"
          variant="flat"
          class="rounded-pill"
          color="accent"
          v-else
          icon
        >
          <v-avatar size="35px">
            <v-img
              :src="
                currentUser.profilePic
                  ? 'data:image/png;base64,' + currentUser.profilePic.file
                  : require('../../assets/Logo_w_transparent.png')
              "
            ></v-img>
          </v-avatar>
        </v-btn>
      </div>
      <v-app-bar-nav-icon
        class="rounded-pill"
        color="accent"
        variant="flat"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
    </v-container>
  </v-app-bar>
  <v-progress-linear
    style="position: fixed; z-index: 9999; left: 0; top: 0"
    v-if="
      $store.state.loading.loadingState || $store.state.loading.success.state
    "
    :indeterminate="!$store.state.loading.success.state"
    class="animate__animated"
    :class="{
      animate__fadeOutUp: $store.state.loading.success.animateOut,
      'bg-success': $store.state.loading.success.state == 'success',
      'bg-error': $store.state.loading.success.state == 'error',
    }"
    color="accent"
  ></v-progress-linear>
  <v-dialog v-model="drawer" app fullscreen>
    <OffCanvasMenu @close="() => (this.drawer = false)" />
  </v-dialog>
</template>

<script>
import OffCanvasMenu from "@/views/Navigation/OffCanvasMenu";
import CartButtom from "@/components/cart/CartButtom.vue";
export default {
  name: "TopbarSide",
  components: { CartButtom, OffCanvasMenu },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    canAccessManagement() {
      return (
        this.currentUser.roles.includes("ROLE_ADMIN") ||
        this.currentUser.roles.includes("ROLE_PROMOTER") ||
        this.currentUser.roles.includes("ROLE_CONF_MANAGER")
      );
    },
    chatPartners() {
      return this.$store.state.chat.currentChatPartners;
    },
    unreadMessages() {
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    },
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    handleLogout: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    gotTo(target) {
      this.$router.push("/" + target);
    },
  },
};
</script>

<style scoped>
.app-header {
  /* border-bottom: 1px solid rgba(235, 235, 235, 0) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important; */
  border-bottom: 1px solid rgba(235, 235, 235, 0) !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  background: linear-gradient(255deg, rgba(151, 71, 255, 1) 0%, #1a1a1a 80vw),
    black !important;
}
.menu-item {
  text-transform: none;
  font-size: 1.3rem !important;
  letter-spacing: 1px;
  font-weight: 400 !important;
  font-family: "Neue-Singular-H", sans-serif !important;
  color: #e0e2db !important;
}
.menu-item:hover {
  color: rgb(var(--v-theme-accentYellow)) !important;
}
</style>
