<template>
  <div class="timetable-wrapper">
    <div v-for="day in eventsWithDays" :key="day.day" class="day-wrapper">
      <h3 class="day-title">{{ dateToday(day.day) }}</h3>
      <div class="timetable-row">
        <div class="times-column">
          <div class="venue-name"></div>
          <div v-for="time in times" :key="time" class="time-slot">{{ time }}</div>
        </div>
        <div class="venues-columns">
          <div v-for="venue in venues" :key="venue.id" class="venue-column">
            <div class="venue-name">{{ venue.name }}</div>
            <div class="time-slot event-slot" v-for="time in times" :data-time="time" :key="time">
              <v-tooltip v-for="event in getEventsAtTime(venue.id, time, day.events)"
                         :key="event.id"
                         location="bottom">
                <template #activator="{props}">
                  <v-sheet v-bind="props"
                      :class="event.event.conference ? 'text-primary day-gradient' : 'text-offwhite night-gradient'"
                      class="event cursor-pointer elevation-1"
                      :style="getEventStyle(event)"
                      @click="routeToEvent(event.event.id)"
                  >
                    <div class="event-title" :style="eventTitleStyle(event.event.title,event.event)">
                      <div>
                        <h3>{{ event.event.title }}</h3>
                        <div v-if="event.event.title.length<=20">
                          /w
                          <small v-for="(artist,index) in event.artists" :key="artist.id" v-text="artist.name" :class="{'artist-name' : event.artists.length > index+1 }" class="mr-2"></small>
                        </div>
                      </div>
                    </div>
                  </v-sheet>
                </template>
                <div v-if="event.artists.length>0">
                  with
                  <span v-for="(artist,index) in event.artists" :key="artist.id" v-text="artist.name" :class="{'artist-name' : event.artists.length > index+1 }" class="mr-2"></span>
                </div>
                <div v-else>
                  Artists to be announced.
                </div>
              </v-tooltip>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeTableWrapper",
  data() {
    return {
      times: this.generateTimes(),
    }
  },
  props:{
    eventsWithDays: Array,
    venues: Array,
  },
  computed:{
    eventTitleStyle() {
      return (title,event) => {
        let duration = this.getDuration(event.dateTime, event.end);
        const maxLength = 15; // Define the maximum length before reducing the font size
        const baseFontSize = 1; // Base font size in rem
        const minFontSize = 0.5; // Minimum font size in rem
        const maxFontSize = 1.5; // Maximum font size in rem

        let fontSize = baseFontSize;

        if (title.length > maxLength) {
          fontSize -= (title.length - maxLength) * 0.02;
        }

        if (duration < 1) {
          fontSize -= 0.2; // Reduce font size for short events
        } else if (duration > 2) {
          fontSize += 0.2; // Increase font size for long events
        }

        return {
          fontSize: `${Math.max(minFontSize, Math.min(fontSize, maxFontSize))}rem`,
        };
      };
    },
  },
  methods: {
   getDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const durationInMilliseconds = end - start;

  // Convert milliseconds to hours
  const durationInHours = durationInMilliseconds / (1000 * 60 * 60);

  return durationInHours;
},
    routeToEvent(eventId) {
      location.href='/event/'+eventId;
    },
    generateTimes() {
      const times = [];
      for (let hour = 9; hour <= 17; hour++) {
        times.push(`${hour.toString().padStart(2, '0')}:00`);
      }
      return times;
    },
    getEventsAtTime(venueId, time, events) {
      return events
          .filter(event => event.event.venue.id === venueId && this.isEventStartingAtTime(event.event, time))
          .map((event, index, array) => ({ ...event, index, total: array.length }));
    },
    isEventStartingAtTime(event, time) {
      const eventStart = new Date(event.dateTime).getHours();
      const slotHour = parseInt(time.split(':')[0]);
      return slotHour === eventStart;
    },
    getEventStyle(event) {
      const startHour = new Date(event.event.dateTime).getHours();
      const endHour = new Date(event.event.end).getHours();

      const startMinutes = new Date(event.event.dateTime).getMinutes();
      const endMinutes = new Date(event.event.end).getMinutes();
      const duration = (endHour + endMinutes/60) - (startHour  + startMinutes/60);
      // const top = (startHour - 9) * 50; // Assuming each hour slot is 50px high
      const top=startMinutes*(50/60);
      const height = duration * 50; // Assuming each hour slot is 50px high
      const width = 100 / event.total; // Calculate width based on the total number of events
      const left = event.index * width; // Calculate left position based on the event index

      return {
        top: `${top}px`,
        height: `${height}px`,
        width: `${width}%`,
        left: `${left}%`,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        borderRadius: '5px',
      };
    },
    dateToday(date) {
      let moment = require("moment");
      return moment(date).format("DD[.]MM dddd");
    },
  },
};
</script>

<style scoped>
.timetable-wrapper {
  display: flex;
  overflow-x: scroll;
  flex-direction: column;
}

.day-wrapper {

  margin-bottom: 20px;
}

.day-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.timetable-row {
  display: flex;
}

.times-column {
  width:100px;
}

.venues-columns {
  display: flex;
  flex: 1;
  position: relative;
}

.venue-column {
  flex: 1;
  border-left: 1px solid #d9d9d91A;
  position: relative;
}

.time-slot {
  height: 50px;
  font-size: 1.3rem;
  padding:25px;
  border-bottom: 1px solid #d9d9d91A;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.time-slot.event-slot{
  min-width: 200px;
}
.venue-name{
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  height:50px;
}
.event {
  z-index: 10;
  position: absolute;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.artist-name:after{
  content: ',';
}
.event-title {
  font-size: 1rem;
  text-align: center;
}
</style>