import axios from "axios";
import store from '../store';
import {router} from "@/router";
import Notification from "@/models/notification";
import ApiService from "@/services/api.service";

/**
 * Returns the Token Object after signIn Process.
 *
 * @param {code} x The Challange Code of Cognito.
 * @return {TokenObject} token and email.
 */
export async function getCognitoToken(code) {
    // Start the loading process
    store.dispatch("loading/startCognitoProcess");

    const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.VUE_APP_FRONTHOST + '/login');
    const body = `client_id=${clientId}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}`;
    const url = "https://bdme.auth.eu-central-1.amazoncognito.com/oauth2/token";
    store.state.loading.loginCognitoProcess.neededEmail = null;

    try {
        const response = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        console.log("Response", response.data);

        let tokenObject = {
            token: response.data.id_token || null,
            extraEmail: null
        };

        if (!tokenObject.token) {
            store.dispatch("notificationsStore/add", new Notification("error", "Login with Cognito did not work! Please try again", false));
            return null;
        }
        store.dispatch("loading/checkNeedingExtraEmail", tokenObject.token);
        let base64Url = tokenObject.token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let object = JSON.parse(jsonPayload);

        if (store.state.loading.loginCognitoProcess.isNeedingExtraEmail) {
            console.log("Cognito User",object['cognito:username'])
            const checkEmailResponse = await ApiService.get(`auth/cognito/getEmailByUsername?username=${object['cognito:username']}`);
            if (checkEmailResponse.data && checkEmailResponse.data.length > 0) {

                tokenObject.extraEmail = checkEmailResponse.data;
                store.state.loading.loginCognitoProcess.isNeedingExtraEmail = false;
            } else {
                console.log("No Email found");
            }
        } else {
            tokenObject.extraEmail = object['custom:provider_email'];
        }
        tokenObject.extraEmail = object['custom:provider_email'];
        return tokenObject;

    } catch (error) {
        console.error("Error during Cognito token retrieval:", error.response ? error.response.data : error.message);
        console.error(error);
        store.dispatch("notificationsStore/add", new Notification("error", "An error occurred during login. Please try again.", false));
        return null;
    } finally {
        store.dispatch("loading/stopCognitoProcess");
    }
}

export async function loginWithCognito(tokenObject) {
    store.dispatch('auth/loginCognito', tokenObject).then(
        () => {
            store.dispatch("loading/stopCognitoProcess");
            router.push('/profile');

        },
        () => {
            store.dispatch("loading/stopCognitoProcess");
            store.dispatch("notificationsStore/add", new Notification("error", "Login with Cognito failed.", false))
        }
    );
}

