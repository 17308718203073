<template>
  <v-sheet
    height="50vh"
    class="d-flex justify-center align-center"
    v-if="page.totalElements === 0 && !loading"
  >
    <h2>Your Feed is empty</h2>
  </v-sheet>

  <div v-else>
    <FeedItem
      v-for="feedItem of feed"
      :key="feedItem.id"
      @updateItem="updateFeedItem"
      :content="feedItem"
    />
    <div ref="scrollTriggerElement"></div>
    <v-progress-circular size="100px" v-if="loading"></v-progress-circular>
    <div v-if="page.last" class="my-5 d-flex justify-center flex-wrap">
      <v-divider class="mx-5" inset></v-divider>
      <span style="width: 100%" class="text-center text--disabled mx-auto"
        >End of feed</span
      >
    </div>
    <div v-if="loading" class="d-flex flex-column" style="gap:20px">
      <v-sheet color="transparent" v-for="n in 5" :key="n" class="mb-8">
        <v-skeleton-loader
            style="width:100%"
          type="list-item-avatar, image, article"
          class="bg-primary mx-auto"
        ></v-skeleton-loader>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import FeedItem from "@/components/feed/FeedItem";
import { debounce } from "lodash";
import {deleteFeedItem} from "@/services/lobby.service";
export default {
  name: "MyFeed",
  components: { FeedItem },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.getFeed();
  },
  methods: {
    loadMore() {
      this.$store.dispatch("feed/loadFeedItems");
    },
    updateFeedItem(item) {
      const index = this.feed.findIndex((x) => x.id === item.id);
      if (index !== -1) {
        this.feed[index] = item;
      }
    },
    async getFeed() {
      await this.$store.dispatch("feed/loadFeedItems");
    },
    isScrolledToBottom() {
      // Calculate the scroll position
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const bodyHeight = document.body.scrollHeight;

      // Check if the scroll position is at the bottom
      return scrollTop + windowHeight >= bodyHeight;
    },
    async onScrollToBottom() {
      console.log("Scrolled to the bottom!");
      if (this.loading || this.$store.state.feed.endOfList) return false;
      this.loading = true;
      await this.$store.dispatch("feed/loadFeedItems");
      this.loading = false;
      // Add your code here to execute when scrolled to the bottom
    },
    handleScroll() {
      // Check if scrolled to the bottom and execute the function
      if (this.isScrolledToBottom()) {
        this.debouncedOnScrollToBottom();
      }
    },
    debouncedOnScrollToBottom: debounce(async function () {
      await this.onScrollToBottom();
    }, 1000),
  },
  mounted() {
    // Add a scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // Remove the scroll event listener when the component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {},
  computed: {
    loading(){
      return this.$store.state.feed.loading;
    },
    page() {
      return this.$store.state.feed.page;
    },
    feed() {
      return this.page.content;
    },
  },
};
</script>

<style scoped>
.v-skeleton-loader {
  max-width: 600px; /* oder die gewünschte Breite Ihrer Feed-Items */
}
</style>
