<template>
  <div class="transparent">
    <ViewHeadline v-if="!iframe" headlineText="Program" />
    <v-container v-else style="height:400px" class="d-flex flex-wrap justify-start align-content-center align-center">
      <v-spacer class="w-100" style="height:100px"></v-spacer>
      <h1 class="text-h2 font-weight-regular text-left" style="font-size: 6rem!important">Program</h1>
    </v-container>
    <v-container class="py-5">
      <v-tabs
        id="filterTabs"
        v-model="whatToShow"
        grow
        class="mt-n3 mb-5"
        background-color="transparent"
      >
        <v-tab value="events" class="filter-chip" size="x-large">
          Events
        </v-tab>
        <v-tab value="artists" class="filter-chip" size="x-large">
          <span v-if="conferenceOrFestival.includes('festival')">Artists</span>
          <span v-if="conferenceOrFestival?.length > 1" class="mx-1"> & </span>
          <span v-if="conferenceOrFestival.includes('conference')"
            >Speaker</span
          >
        </v-tab>
        <v-tab value="venues" class="filter-chip" size="x-large">
          Venues
        </v-tab>
      </v-tabs>
      <ProgramFilter @loading="updateLoadingState"/>
    </v-container>
    <v-window class="scroll" v-model="whatToShow">
      <v-window-item value="events">
        <v-list v-if="!loading && !loadingState" class="bg-primary events">
          <div v-if="eventsWithDays?.length <= 0">
            <h3 class="text-center">Nothing matches your search :(</h3>
          </div>

          <div v-else style="position: relative">
            <v-container
              class=""
              v-for="day of eventsWithDays"
              :key="day.day"
            >
              <h3
                class="text-uppercase font-weight-regular text-color-accent mt-2"
              >
                {{ dateToday(day.day) }}
              </h3>

              <div class="py-2" v-for="event of day.events" :key="event.id">
                <EventItem
                    :iframe="iframe"
                  :event="event.event"
                  :artists="event.artists"
                  @addToFavourites="(id) => event.event.savedByIds.push(id)"
                  @removeFromFavourites="
                    (id) =>
                      event.event.savedByIds.splice(
                        event.event.savedByIds.indexOf((x) => (x.id = id)),
                        1
                      )
                  "
                />
              </div>
            </v-container>
          </div>
        </v-list>

        <!-- Skeleton loader während des Ladens -->

        <v-container v-else>
          <div v-for="n in 5" :key="n">
            <v-card
              flat
              class="my-2 pa-8 bg-grey-darken-4 custom-border-radius"
            >
              <v-row>
                <v-col cols="2" class="hidden-sm-and-down">
                  <v-skeleton-loader
                    class="rounded-lg"
                    color="transparent"
                    width="80%"
                    height="80%"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="10" class="pt-1">
                  <v-skeleton-loader
                    class="mt-2"
                    type="heading"
                    color="transparent"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="mt-2"
                    type="text"
                    color="transparent"
                  ></v-skeleton-loader>
                  <div
                    class="d-flex ga-1 justify-space-between flex-wrap ml-3 mt-5"
                  >
                    <div class="d-flex ga-2 flex-wrap">
                      <v-skeleton-loader
                        class="mr-2"
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        class="mr-2"
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                    </div>
                    <v-skeleton-loader
                      width="100"
                      type="chip"
                      color="transparent"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-container>
      </v-window-item>
      <v-window-item value="artists">
        <ArtistListPublic :loading-state="loadingState"/>
      </v-window-item>
      <!--Artists-->

      <v-window-item value="venues">
        <FilteredVenues :loading-state="loadingState"/>
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import EventItem from "@/views/Event/EventItem";
import ArtistListPublic from "@/components/artist/ArtistListPublic.vue";
import { getAllVisibleArtists } from "@/services/artist.service";
import ViewHeadline from "@/components/helpers/ViewHeadline.vue";
import ProgramFilter from "@/components/event/ProgramFilter.vue";
import FilteredVenues from "@/components/venue/FilteredVenues.vue";
export default {
  name: "EventSite",
  components: {FilteredVenues, ProgramFilter, ArtistListPublic, EventItem, ViewHeadline },
  data() {
    return {
      loadingState:false,
      days: [],
      selectedGenres: [],
      selectGenre: false,
      whatToShow: "events",
      events: [],
      loading: false,
      searchQuery: "",
      conferenceOrFestival: ["festival", "conference"],
      selectedVenues: [],
      selectedDays: [],
      allArtists: [],
      selectedCTopics: [],
      selectedCTypes: [],
    };
  },
  async created() {
    this.loading = true;
    try {
      this.$store.dispatch("programStore/loadFilterValues");
      this.$store.dispatch("programStore/loadEventsGroupedByDay",this.filter);
    } catch (error) {
      console.error("Fehler beim Laden der Daten:", error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    iframe(){
      return this.$route.meta.iframe;
    },
    eventsWithDays(){
      return this.$store.state.programStore.events;
    },
    filterValues(){
      return this.$store.state.programStore.filterValues;
    },
    filter:{
      get(){
        return this.$store.state.programStore.filter;
      },
      set(value){
        this.$store.commit("programStore/setFilter", value);
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    allVenues() {
      let uniqueVenues = new Set();
      this.days.forEach((day) => {
        day.events.forEach((event) => {
          if (event.event.venue) uniqueVenues.add(event.event.venue);
        });
      });
      return uniqueVenues;
    },
  },
  methods: {
    updateLoadingState(state){
      this.loadingState= state;
    },
    dateToday(date) {
      let moment = require("moment");
      return moment(date).format("DD[.]MM dddd");
    },
    sendHeight() {
      const height = document.body.scrollHeight;
      parent.postMessage({ type: 'setHeight', height }, '*'); // Or replace * with the exact origin for security
    },
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    }
  },
  mounted() {
    this.sendHeight();
    this.debouncedSendHeight = this.debounce(this.sendHeight, 100);
    window.addEventListener('resize', this.debouncedSendHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedSendHeight);
  }
};
</script>

<style scoped>

.filter-chip {
  padding: 1em 2em;
  opacity: 1;
}
.filter-chip-active {
  opacity: 1 !important;
}
.tab-active .filter-chip {
  opacity: 1;
}
</style>
