<template>
  <v-container>
  <v-row>
    <v-skeleton-loader height="270" v-for="n in 10" :key="n" style="border-radius: 20px" class="v-col-6 v-col-md-3 border-lg" v-if="loading || loadingState" color="transparent" />
    <p class="mx-auto pa-7" v-else-if="!loading && items?.length<=0">No Artists found</p>
    <v-col v-for="item of items" :key="item.id" cols="6" md="3">
      <UserSheetWall
          :key="item.id"
          :index="item.id"
          :hover="false"
          :user="item"
      />
    </v-col>
  </v-row>
  <v-pagination
      class="my-5"
      v-if="artistsPage.content"
      v-model="artistsPage.number"
      :start="0"
      :length="artistsPage.totalPages"
      @update:modelValue="onPageChange">
    <template #item="{page,isActive}">
      <v-btn
          icon
          variant="tonal"
          @click="onPageChange(page)"
      >
        {{parseInt(page)+1}}
      </v-btn>
    </template>

  </v-pagination>
  </v-container>
</template>

<script>
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import ApiService from "@/services/api.service";

export default {
  name: "ArtistListPublic",
  components: { UserSheetWall },
  data(){
    return{
      loading:false,
    }
  },
  props: {
    loadingState: Boolean,
    artists: Array,
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("programStore/loadArtistsFiltered", {page: 0, filter: this.filter});
    this.loading = false;
  },
  methods:{
    async onPageChange(page){
      window.scrollTo(0, 0);
      return this.loadPage(page);

    },
    async loadPage(page = 0) {
      this.loading = true;
      try {
        await this.$store.dispatch("programStore/loadArtistsFiltered", {page: page, filter: this.filter});
      } catch (error) {
        console.error("Failed to load users:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    filter(){
      return this.$store.state.programStore.filter;
    },
    items(){
      return this.artistsPage.content;
    },
    artistsPage:{
        get(){
      return this.$store.state.programStore.artists;
    },
      set(value){
        this.$store.dispatch("programStore/setArtists",value);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media screen and (max-width: 600px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0 10px;
  }

  .wrapper > *:nth-child(even) {
    transform: translateY(30px);
  }
}
.wrapper > div {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
</style>
