<template>
  <v-navigation-drawer
    location="right"
    temporary
    :modelValue="true"
    @update:modelValue="closeDialog"
    color="primary"
    width="400"
    class="pa-4"
  >
    <div class="position-absolute top-0 right-0">
      <v-btn icon variant="plain" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="d-flex flex-column h-100">
      <!-- Navigation Items -->
      <div>
        <div
          v-for="navItem in $store.state.app.navItems"
          :key="navItem.title"
          class="my-2"
        >
          <v-btn
            :block="!navItem.title === 'Tickets'"
            style="font-size: 2rem"
            size="large"
            :variant="navItem.title === 'Tickets' ? 'flat' : 'text'"
            :color="navItem.title === 'Tickets' ? 'accentYellow' : ''"
            class="text-capitalize text-left justify-start my-2"
            :class="navItem.title === 'Tickets' ? 'rounded-pill' : ''"
            @click="
              navItem.target === 'shop'
                ? openExternalLink(
                    'https://shop.berlin-dance-music-event.com/0f38c67711884e5d9962a183138123a6/welcome-to-the-bdme25-ticketshop'
                  )
                : gotTo(navItem.target)
            "
          >
            {{ navItem.title }}
          </v-btn>
        </div>

        <!-- Management Button -->
        <div v-if="canAccessManagement" class="my-2">
          <v-btn
            block
            variant="text"
            class="text-capitalize text-left justify-start"
            href="/management"
            prepend-icon="mdi-lock-open"
          >
            Conference Management
          </v-btn>
        </div>

        <!-- BDME Website Button -->
        <div class="my-2">
          <v-btn
            block
            variant="text"
            class="text-capitalize text-left justify-start"
            href="https://berlin-dance-music-event.com"
            target="_blank"
          >
            BDME Website
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      <!-- Logout Button -->
      <v-btn
        color="error"
        variant="text"
        prepend-icon="mdi-logout"
        @click="handleLogout"
        class="text-left justify-start mt-auto mb-4"
      >
        Log out
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "OffCanvasMenu",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    canAccessManagement() {
      return (
        this.currentUser.roles.includes("ROLE_ADMIN") ||
        this.currentUser.roles.includes("ROLE_PROMOTER") ||
        this.currentUser.roles.includes("ROLE_CONF_MANAGER")
      );
    },
  },
  methods: {
    handleLogout: function () {
      this.$store.dispatch("auth/logout");
      this.$emit("close");
      this.$router.push("/");
    },
    closeDialog() {
      this.$emit("close");
    },
    gotTo(target) {
      this.$router.push("/" + target);
      this.$emit("close");
    },
    openExternalLink(url) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.opener = null;
        newWindow.location = url;
      }
    },
  },
};
</script>

<style scoped></style>
