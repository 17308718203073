<script>
import ApiService from "@/services/api.service";

export default {
  name: "ClearCacheBtn",
  methods: {
    async clearCache() {
      await ApiService.get("cache/clear");
    },
  },
}
</script>

<template>
  <v-btn @click="clearCache" color="primary">Clear Cache</v-btn>
</template>

<style scoped>

</style>