<template>
  <v-container class="d-flex flex-wrap" style="gap: 5px;">
    <v-row>
    <v-skeleton-loader
      v-if="loading || loadingState"
      class="mr-2"
      width="300"
      height="200"
      color="transparent">
    </v-skeleton-loader>
    <p v-else-if="!loading && venues.length<=0">No Venues found</p>
    <v-col cols="4" style="height:200px;" v-for="venue of venues" :key="venue.name">
      <VenueItem  :venue="venue" />
    </v-col>
    </v-row>

  </v-container>
</template>

<script>
import VenueItem from "@/components/venue/VenueItem";
import {getAllVenues} from "@/services/venue.service";
export default {
  name: "FilteredVenues",
  components: {VenueItem},
  data(){
    return{
      loading:false,
    }
  },
  props:{
    loadingState: Boolean,
  },
  methods:{
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("programStore/loadVenuesFiltered", this.filter);
    this.loading = false;
  },
  computed:{
    filter(){
      return this.$store.state.programStore.filter;
    },
    venues(){
      return this.$store.state.programStore.venues;
    }
  }
}
</script>

<style scoped>

</style>