<script>
import TimeTableWrapper from "@/components/timetable/TimeTableWrapper.vue";

export default {
  name: "TimetableView",
  components: {TimeTableWrapper},
  created() {
    this.$store.dispatch("programStore/loadFilterValues");
    this.$store.dispatch("programStore/loadVenuesFiltered", this.filter);
    this.$store.dispatch("programStore/loadEventsGroupedByDay", this.filter);
  },
  computed: {
    eventsWithDays(){
      return this.$store.state.programStore.events;
    },
    venues(){
      return this.$store.state.programStore.venues;
    },
    filterValues(){
      return this.$store.state.programStore.filterValues;
    },
    filter(){
      return this.$store.state.programStore.filter;
    }
  }
}
</script>

<template>
<v-container>
  <TimeTableWrapper :events-with-days="eventsWithDays" :venues="venues"/>
</v-container>
</template>

<style scoped>

</style>