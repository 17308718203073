import { createStore } from 'vuex';

import { auth } from './auth.module';
import { loading } from './loading.module';
import { chat } from './chat.module';
import { notificationsStore } from '@/store/notifications.module';
import { events } from '@/store/events.module';
import { network } from '@/store/network.module';
import { feed } from '@/store/feed.module';
import { app } from './app.module';
import { cart } from '@/store/cart.store';
import { checkout } from '@/store/checkout.store';
import { programStore} from "@/store/program.module";

const store = createStore({
  modules: {
    feed,
    cart,
    auth,
    loading,
    chat,
    notificationsStore,
    events,
    network,
    app,
    checkout,
    programStore

  }
});

export default store;