import ApiService from "@/services/api.service";
import {getAllDaysWithEvents} from "@/services/event.service";
let filterStorage= localStorage.getItem('program-filter');
let filter = null;
if(filterStorage && filterStorage!=="undefined") filter=JSON.parse(filterStorage);
if (!filter || !hasAllFilterFields(filter)) {
    filter= {
        selectedDates:[],
            selectedGenres: [],
            selectedDays: [],
            selectedTypes:[],
            selectedConferenceTypes: [],
            selectedConferenceTopics: [],
    }
}
const initialState = {
    filterValues: {},
    filter: filter,
    artists: {},
    events: {},
    venues: {}
}

function hasAllFilterFields(filter) {
    const requiredFields = [
        'selectedDates',
        'selectedGenres',
        'selectedDays',
        'selectedTypes',
        'selectedConferenceTypes',
        'selectedConferenceTopics'
    ];

    return requiredFields.every(field => Array.isArray(filter[field]));
}

    export const programStore = {
        namespaced: true,
        state: initialState,
        actions: {
            setArtists({commit},pageObject){
                commit("setArtists",pageObject);
            },
            setFilter({commit},filter){
                commit("setFilter",filter);
            },
            async loadFilterValues({commit}) {
                let response = await ApiService.betterGet("filter/program/get").catch(error => {
                    console.log(error);
                    commit("pushError", error);
                });
                commit("setFilterValues", response.data);
            },
            async loadEventsGroupedByDay({commit},filter) {
                let data = await getAllDaysWithEvents(filter).catch(error => {
                    console.log(error);
                    commit("pushError", error);
                });
                localStorage.setItem('program-filter', JSON.stringify(filter));
                commit("setEvents", data);
            },
            async loadArtistsFiltered({commit}, {page, filter}) {
                let response = await ApiService.betterPost("artist/get/all/visible/page/"+page, filter).catch(error => {
                    console.log(error);
                    commit("pushError", error);
                });
                localStorage.setItem('program-filter', JSON.stringify(filter));
                commit("setArtists", response.data);
            },
            async loadVenuesFiltered({commit}, filter) {
                let response = await ApiService.betterPost("venue/filtered", filter).catch(error => {
                    console.log(error);
                    commit("pushError", error);
                });
                localStorage.setItem('program-filter', JSON.stringify(filter));
                commit("setVenues", response.data);
            }

        },
        mutations: {
            setFilterValues(state, filterValues) {
                state.filterValues = filterValues;
            },
            setEvents(state, events) {
                state.events = events;
            },
            setArtists(state, artists) {
                state.artists = artists;
            },
            setFilter(state, filter) {
                state.filter = filter;
            },
            setVenues(state,venues){
                state.venues = venues;
            }
        }
}