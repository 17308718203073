<template>
  <v-card
    @click="navigateToUser(user.id)"
    color="primary"
    class="wall-item border-0"
    :style="{ height: size * 200 + 'px' }"
  >
    <v-btn
      @click.exact.stop="$router.push('/profile')"
      small
      style="z-index: 2"
      class="me-btn"
      color="white"
      v-if="currentUser && (user.id === currentUser.id && !profileView)"
    >
      Me <v-icon small>mdi-pencil</v-icon>
    </v-btn>

    <v-img
      v-if="profileView"
      height="100%"
      width="100%"
      cover
      :src="
        user.profilePic?.file
          ? 'data:image/webp;base64,' + user.profilePic?.file
          : require('../../assets/Placeholder-Profile-819x1024.webp')
      "
      class="background-image"
    >
    </v-img>
    <v-img
      v-else
      height="100%"
      width="100%"
      cover
      :src="image"
      class="background-image"
    ></v-img>

    <div class="content-wrapper" :class="backgroundGradient">
      <div class="d-flex flex-column justify-end h-100">
        <span style="font-size: 1.5rem">
          <span v-html="user.name"></span>
        </span>
        <span>
          <span v-if="user.role">{{ user.role }}</span>
        <span v-else-if="user.professions.length > 0">{{
            user.professions[0].role
          }}</span>
        <span v-else-if="user.roles?.includes('ROLE_ARTIST')">Artist</span>
        <span v-else>
          User
        </span>
          <span v-if="user.reference"> - {{user.reference}}</span>
        </span>

      </div>
    </div>
  </v-card>
</template>
<script>
import ApiService from "@/services/api.service";

export default {
  name: "UserSheetWall",
  props: {
    profileView: Boolean,
    hover: {},
    user: {},
    size: {
      type: String,
      default: "0.6",
    },
    index: Number,
  },
  data() {
    return {
      tags: [],
      professions: [],
    };
  },
  created() {
    // this.getUserTags();
    //this.getProfessions(this.user.id);
  },
  methods: {
    getUserTags() {
      let self = this;
      ApiService.post("tags/get/custom", { ids: self.user.tags }).then(
        (response) => {
          self.tags = response.data;
          self.$store.dispatch("loading/success");
        }
      );
    },
    getProfessions(userId) {
      let self = this;
      ApiService.get("company/find/from/" + userId).then((response) => {
        self.professions = response.data;
      });
    },
    navigateToUser(userId) {
      location.href = "/user/" + userId;
    },
  },
  computed: {
    image(){
      if(this.user.image){
        return 'data:image/webp;base64,' + this.user.image;
      }
      else if(this.user.profilePicture){
        return 'data:image/webp;base64,' + this.user.profilePicture;
      }
      else{
        return require('../../assets/Placeholder-Profile-819x1024.webp');
      }
    },
    userWithArtistName() {
      let list = ["ROLE_ARTIST", "ROLE_CONF_MANAGER", "ROLE_PROMOTER"];
      console.log("UserRoles", this.user.roles);
      for (let role of list) {
        let hasRole = this.user.roles.findIndex((x) => x.name === role) !== -1;
        if (hasRole) return true;
      }
      return false;
    },
    backgroundGradient() {
      if (this.user.role === "Artist") {
        return "artist-gradient";
      } else if (
        this.user.role === "Speaker" ||
        this.user.role === "Speaker & Artist"
      ) {
        return "speaker-gradient";
      } else {
        return "user-gradient";
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
<style scoped>
.wall-item {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 250px !important;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.wall-item:hover {
  transform: scale(1.02);
}

.me-btn {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 3;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.content-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
}
.artist-gradient {
  background: linear-gradient(to bottom, transparent 30%, #9747ff 150%);
}

.speaker-gradient {
  background: linear-gradient(to bottom, transparent 30%, #bdd600 150%);
}

.user-gradient {
  background: linear-gradient(to bottom, transparent 0%, #28202e 100%);
}

.user-title {
  font-size: 0.9em;
  opacity: 0.9;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.companies-list {
  display: block;
  font-size: 0.85em;
  opacity: 0.8;
  margin-top: 8px;
}

.box--double {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}

@media (max-width: 959px) {
  .wall-item {
    height: 250px !important;
    margin: 0;
  }

  .content-wrapper {
    padding: 12px;
  }

  .user-name {
    font-size: 1.2em !important;
  }

  /* Deaktiviere double-size für mobile */
  .box--double {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
    height: 250px !important;
    width: calc(50% - 8px) !important;
  }
}

/* Für sehr kleine Bildschirme (z.B. schmale Smartphones) */
@media (max-width: 480px) {
  .wall-item {
    height: 200px !important;
    width: 100% !important;
    margin: 0;
    transition: transform 0.3s ease;
  }

  .box--double {
    height: 200px !important;
    width: 100% !important;
  }
}
</style>
