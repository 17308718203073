<template>
  <v-card
    :dark="!event.conference"
    :color="event.conference ? 'offwhite' : 'primary'"
    :class="event.conference ? 'day-gradient' : 'night-gradient'"
    :border="event.conference ? '0' : 'sm opacity-100 grey'"
    :to="iframe ? '/u/event/' + event.id+'?iframe=true' : `/event/${event.id}`"
    style="position: relative"
    flat
    class="my-2 pa-5 custom-border-radius"
  >
    <v-row>
      <v-col cols="2" class="hidden-sm-and-down">
        <div class="d-flex justify-start">
          <!-- <v-img
            style="margin-right: -50px"
            class="hvr-front rounded-circle"
            max-width="75px"
            width="75px"
            height="75px"
            v-for="artist of artists"
            :key="artist.id"
            :src="
              artist.profilePicture
                ? 'data:image/png;base64,' + artist.profilePicture
                : require('../../assets/Placeholder-Profile-819x1024.webp')
            "
          /> -->
          <v-carousel class="rounded-lg"
                      width="100%"
                      cycle
                      :show-arrows="false"
                      hide-delimiters
                      direction="vertical"
                      height="100%">
            <v-carousel-item
              v-for="artist of artists"
                :aspect-ratio="1"
                :src="
              artist.profilePicture
                ? 'data:image/png;base64,' + artist.profilePicture
                : require('../../assets/Placeholder-Profile-819x1024.webp')
            "
            />
          </v-carousel>

        </div>
      </v-col>
      <v-col
        cols="12"
        md="10"
        class="pt-1 pr-7 d-flex flex-column justify-space-between"
      >
        <v-list-item class="pt-0 px-0">
          <v-list-item-title
            class="text-wrap"
            style="text-transform: capitalize"
            :class="$vuetify.display.mdAndUp ? 'desktop-title' : 'mobile-title'"
            >{{ event.title }}</v-list-item-title
          >
          <v-list-item-subtitle class="pt-1">
            <span>/w </span>
            <span
              class="text-uppercase"
              v-for="(artist, $index) of artists"
              :key="$index"
            >
              {{ artist.name
              }}<span v-if="$index < artists.length - 1">, </span>
            </span>
          </v-list-item-subtitle>
        </v-list-item>
        <div class="d-flex ga-1 justify-space-between flex-wrap mt-10">
          <div class="d-flex ga-2 flex-wrap">
            <v-chip
              v-if="event.venue"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              variant="outlined"
              density="compact"
              prepend-icon="mdi-map-marker"
              >{{ event.venue.name }}</v-chip
            ><v-chip
              v-if="event.dateTime"
              prepend-icon="mdi-clock"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              variant="outlined"
              density="compact"
              >{{ duration }}</v-chip
            ><v-chip
              v-if="event.conferenceType"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              variant="tonal"
              density="compact"
              >{{ event.conferenceType }}</v-chip
            >
          </div>
          <div>
            <span v-if="!currentUser">
              <v-btn variant="text" @click.prevent="loginToSaveFavourites" icon
                ><v-icon>mdi-bookmark-outline</v-icon></v-btn
              >
            </span>
            <v-btn
              variant="outlined"
              v-else-if="currentUser.favourites?.includes(event.id)"
              append-icon="mdi-bookmark"
              :size="$vuetify.display.mdAndUp ? 'default' : 'small'"
              @click.prevent="removeFavourite(event.id)"
              >Entfernen</v-btn
            >
            <v-btn
              variant="outlined"
              :size="$vuetify.display.mdAndUp ? 'default' : 'small'"
              v-else
              append-icon="mdi-bookmark-outline"
              @click.prevent="addToFavourites(event.id)"
              >Speichern</v-btn
            >

            <v-chip
              v-if="event.ticketLink"
              class="ml-2"
              append-icon="mdi-arrow-top-right"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'large'"
              variant="flat"
              color="accentYellow"
              density="compact"
              :href="event.ticketLink"
              target="_blank"
              @click.stop
              >Tickets</v-chip
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApiService from "@/services/api.service";
import moment from "moment";
import Notification from "@/models/notification";
import Event from "@/models/event";
export default {
  name: "EventItem",

  props: {
    iframe: Boolean,
    artists: Array,
    event: Event,
  },
  data() {
    return {
      backgroundOpacity: 0.12,
    };
  },
  // created() {
  //   this.event.conference = false;
  // },
  computed: {
    isConference() {
      return this.event.conference;
    },
    duration() {
      moment.locale("en");
      if (this.event.end) {
        let start = moment(this.event.dateTime);
        let end = moment(this.event.end);
        let duration = moment.duration(end.diff(start));
        return `${start.format("HH:mm")} - ${end.format("HH:mm")} (${Math.floor(
          duration.asHours()
        )}h ${duration.minutes()}min)`;
      }
      return moment(this.event.dateTime).format("HH:mm");
    },
    ticketLink() {
      return this.event.ticketLink;
    },
    eventDay() {
      moment.locale("en");
      return moment(this.event.dateTime).format("dddd");
    },
    currentUser: {
      get() {
        return this.$store.state.auth.user;
      },
      set(data) {
        this.$store.state.auth.user = data;
      },
    },
  },
  watch: {
    "$vuetify.theme.dark"() {
      if (this.$vuetify.theme.dark) this.backgroundOpacity = 0.02;
      else this.backgroundOpacity = 0.12;
    },
  },

  methods: {
    addToFavourites(id) {
      if (!this.currentUser) return;

      const user = { ...this.currentUser };
      if (!user.favourites) {
        user.favourites = [];
      }
      console.log("User: ", user);
      if (!user.favourites?.includes(id)) {
        user.favourites.push(id);
        this.updateUser();

        ApiService.get(`event/favourites/add/${this.event.id}`);
        this.$emit("addToFavourites", user.id);
      }
    },
    removeFavourite(id) {
      if (this.currentUser.favourites?.includes(id)) {
        this.currentUser.favourites.splice(
          this.currentUser.favourites.indexOf(id),
          1
        );
        this.updateUser();
        ApiService.get("event/favourites/remove/" + this.event.id);
        this.$emit("removeFromFavourites", this.currentUser.id);
        //this.event.savedByIds.splice(this.event.savedByIds.indexOf(x=> x.id=this.currentUser.is),1);
      }
    },
    loginToSaveFavourites() {
      this.$store.dispatch(
        "notificationsStore/add",
        new Notification(
          "warning",
          "Login to save Favourites.",
          false,
          null,
          "/login",
          "Login now"
        )
      );
    },
    async updateUser() {
      try {
        const response = await ApiService.post("user/update", this.currentUser);
        const user = JSON.parse(localStorage.getItem("user"));
        const merged = { ...user, ...response.data };
        await this.$store.dispatch("auth/updateLocalUser", merged);
        await this.$store.dispatch("loading/success");
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Benutzers:", error);
      }
    },
  },
};
</script>

<style scoped>
.event-box-overlay {
  background-position: 0, 50%;
  background-image: url("../../assets/wellen-2.webp");
  background-size: cover;
  opacity: 0.12;
}
.event-box {
  border-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 2px 2px 2px 2px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-color: #bcbcbc;
  border-top-color: rgb(188, 188, 188);
  border-right-color: rgb(188, 188, 188);
  border-bottom-color: rgb(188, 188, 188);
  border-left-color: rgb(188, 188, 188);
}
.desktop-title {
  font-size: 1.6rem;
}
.mobile-title {
  font-size: 1.1rem;
}
</style>
