<template>
  <div style="width:100%;height:100%;">
  <div style="width:auto;height:100%;position:relative" class="mx-auto custom-border-radius">
    <v-img class="custom-border-radius" style="position:absolute;top:0;left:0;max-width:100%;max-height:100%;" width="100%" height="100%" :src="image ? 'data:image/png;base64,'+image :require('@/assets/Placeholder-Profile-819x1024.webp')">
    </v-img>
    <v-chip color="accent" variant="flat" style="position:absolute;left:5px;bottom:5px;">
      Click to edit
      <v-icon class="ml-1">mdi-camera</v-icon>
    </v-chip>
      <input type="file" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:1;opacity: 0;" placeholder="File" @change="setImage"/>
  </div>
    <v-dialog content-class="overflow-hidden" fullscreen max-width="100%" style="max-height: 100%;" v-model="hasImage">
      <v-card>
        <v-card-actions>
          <v-btn variant="text" @click="img=null">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="crop"  :loading="loading">Save</v-btn>
        </v-card-actions>
        <v-chip color="accent" variant="flat" style="position:absolute;bottom:50px;left:50px;z-index:99">Use your Fingers or Mousewheel to zoom <v-icon>mdi-gesture-pinch</v-icon></v-chip>
        <cropper
            ref="cropper"
            class="upload-example-cropper"
            :src="img"
            style="width:100%; height:100%;max-height: 90vh"
            :stencil-props="{
		handlers: {},
		movable: false,
		scalable: false,
	}"
            :stencil-size="{
		width: 280,
		height: 280
	}"
            image-restriction="stencil"
            :stencil-component="$options.components.Stencil"


        />

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {compressImageCustom} from "@/services/image.service";
import {Cropper} from "vue-advanced-cropper";
export default {
  name: "ImagePickerWithCropping",
  components: {Cropper},

  props:{
    image:String
  },
  data(){
    return{
      img:null,
      loading:false
    }
  },
  computed:{
    hasImage:{
      get(){
        return this.img!=null
      },set(value){
        if(!value) this.img=null;
      }
    }
  },
  methods:{
    setImage: function (e) {
      //this.hasImage = true
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      else this.img = URL.createObjectURL(files[0]);

    },
    crop() {
      this.loading=true;
      const result = this.$refs.cropper.getResult();
      this.compressImage(this.dataURItoBlob(result.canvas.toDataURL()));

    },
    dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
    },
    compressImage(file){
      let compressedFile = compressImageCustom(file,0.1,300).file;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.loading=false;
        this.$emit("updateImage", event.target.result.split(',')[1]);
        this.img=null;
      }
      reader.readAsDataURL(compressedFile);
    },
  }
}
</script>

<style scoped>

</style>