<template>
  <a
    :href="venue.website"
    target="_blank"
    style="width:100%;height: 100%;display:block; overflow: hidden; position: relative"
    class="custom-border-radius"
    :class="{ 'hvr-grow-rotate': venue.website }"
  >
    <v-img
      cover
      class="custom-border-radius"
      style="
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
      "
      :src="image"
    ></v-img>
    <div
      class="custom-border-radius"
      style="
        position: absolute;
        top: 0;
        left: 0;
        background-color: black;
        z-index: 1;
        opacity: 0.7;
        width: 100%;
        height: 100%;
      "
    ></div>
    <span
      style="
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        font-size: 1.4rem;
      "
      class="text-secondary pa-3 text-uppercase"
      v-text="venue.name"
    ></span>
  </a>
</template>

<script>
import Venue from "@/models/venue";
import { addMetaToBase64 } from "@/services/image.service";

export default {
  name: "VenueItem",
  props: {
    venue: Venue,
  },
  computed: {
    image() {
      return addMetaToBase64(
        this.venue.image,
        require("@/assets/Placeholder-Profile-819x1024.webp")
      );
    },
  },
};
</script>

<style scoped></style>
