<script>
import {changeStateOfEvent} from "@/services/event.service";

export default {
  name: "PublishEventBtn",
  props:{
    eventId: Number,
    state: String
  },
  data(){
    return{
      loading: false,
      success:false,
    }
  },
  methods:{
    async publish() {
      this.loading = true;
      try{
        await changeStateOfEvent(this.eventId, "PUBLISHED");
        this.success=true;
      }catch (e) {
        console.error(e);
      }finally {
        this.loading = false;
      }
    },
    async draft(){
      try{
        this.loading = true;
        await changeStateOfEvent(this.eventId, "DRAFT");
        this.success=false;
      }catch (e) {
        console.error(e);
        }finally {
        this.loading = false;
      }
    }
  }
}
</script>

<template>
  <v-btn v-if="state!=='PUBLISHED' && !success" @click="publish" :loading="loading" size="small" color="accent">Publish Event</v-btn>
  <v-btn v-if="success" @click="draft" :loading="loading" size="small" variant="plain">Make Event Draft again</v-btn>
</template>

<style scoped>

</style>