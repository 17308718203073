import ApiService from "@/services/api.service"

export async function getAllVenues(){
    let response = await ApiService.get("venue/all");
    return response.data;
}
export async function getFilteredVenues(filter){
    let response = await ApiService.post("venue/filtered",filter);
    return response.data;
}

export async function getAllVenueNames(){
    let response = await ApiService.get("venue/names/get");
    return response.data;
}

export async function updateVenue(venue){
    let response = await ApiService.post("venue/update",venue);
    return response.data;
}

export async function deleteVenue(id){
    let response = await ApiService.get("venue/delete/"+id);
    return response.data;
}
