<template>
<v-list-item :dark="isFestival" :class="{'bg-offwhite':!isFestival , 'bg-black': isFestival}" :lines="3" class="mx-5 px-10 py-5 my-2">
  <template #title>
<span class="font-weight-bold text-uppercase mr-1" v-if="event.event.conferenceType" v-text="'['+event.event.conferenceType+']'"></span><span v-text="event.event.title" class="font-weight-bold mr-5" style="font-size: 1rem;"></span>
  </template>
    <template #subtitle>
      <div>/w <span v-for="artist of event.artists" :key="artist.id">
        {{artist.name}}
      </span>

      </div>
      <div>{{start}} ({{duration}})</div>
      <div><v-chip class="mr-1" x-small v-for="topic in event.event.conferenceTopics" :key="topic" v-text="topic"></v-chip></div>

      <div class="d-flex align-center mt-2">
            <v-icon v-tooltip="'Active in current Festival'" v-if="event.event.activeInCurrentEventRotation" class=" custom-border-radius" color="success">mdi-eye</v-icon>



            <v-icon v-else v-tooltip="'Not active'" color="red" class="custom-border-radius">mdi-eye-off</v-icon>

        <v-btn color="red" variant="text" class="" size="small" icon @click="deleteEvent"><v-icon small>mdi-delete</v-icon></v-btn>
        <PublishEventBtn :event-id="event.event.id" :state="event.event.state"/>
      </div>


    </template>
  <template #append>
    <v-menu offset-y>
      <template #activator="{ props}">
        <v-btn icon variant="text" v-bind="props">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-dialog max-width="600px" v-model="showShareDialog" persistent v-if="isMyEvent || hasRoleAdmin">
          <template v-slot:activator="{props}">
            <v-list-item link v-bind="props">
              <template #title>
                Add Editor
              </template>
              <template #subtitle>
                Editors can edit the event
              </template>
              <template #append>
                <v-icon>mdi-share</v-icon>
              </template>
            </v-list-item>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <ShareToUser @updateShareList="updateEventShareList" :editable-by="event.event.editableBy"/>
              <div class="d-flex justify-end">
                <v-btn variant="plain" @click="showShareDialog = false"><v-icon>mdi-close</v-icon></v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-list-item disabled v-else>
          <template #append>
            <v-icon class="mx-auto">mdi-account-supervisor</v-icon>
          </template>
          <template #title>
            Add Editor
          </template>
          <template #subtitle>
            Only the creator can add editors
          </template>
        </v-list-item>
        <EditEvent @update="updateEvent" :event="event.event">
          <template #activator="{props}">
            <v-list-item
                icon
                v-bind="props"
            >
              <template #title>
                Edit Event
              </template>
              <template #append>
                <v-icon>mdi-playlist-edit</v-icon>
              </template>
            </v-list-item>
          </template>
        </EditEvent>


      </v-list>
    </v-menu>

  </template>
</v-list-item>
</template>

<script>
import EditEvent from "@/components/management/events/EditEvent";
import ShareToUser from "@/components/share/ShareToUser";
import {deleteEvent, shareEvent} from "@/services/event.service";
import PublishEventBtn from "@/views/conferenceManagement/PublishEventBtn.vue";
export default {
  name: "AdminEventListItem",
  components: {PublishEventBtn, ShareToUser, EditEvent},
  data(){
    return{
      showShareDialog:false,
    }
  },
  props:{
    event:Object
  },
  computed:{
    isFestival(){
      return !this.event.event.conference;
    },
    shareLength(){
      return this.event.event.editableBy.length;
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN")
    },
    isMyEvent(){
      return this.event.event.createdBy === this.currentUser.id;
    },
    start(){
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("LLL");
    },
    duration(){
      let moment = require("moment");
      if(!this.event.event.end) return "Open End"
      let hours = moment(this.event.event.end).diff(this.event.event.dateTime,'hours',true);
      return hours.toFixed(1)+"h";
    }
  },
  methods:{
    updateEvent(event){
      console.log("Update",event);
     this.$emit("updateEvent",event)
    },
    async deleteEvent(){
      if(confirm("Are you sure? you will be deleting this event.")) {
        let response = await deleteEvent(this.event.event.id);
        //TODO Catch in parent
        this.$emit("delete", this.event.event.id);
        return response;
      }
    },
    async updateEventShareList(items) {
      let editableBy = await shareEvent(this.event.event.id, items);
      let event = JSON.parse(JSON.stringify(this.event));
      event.event.editableBy= editableBy;
      this.$emit("updateEvent",event?.event);
      this.showShareDialog = false;
    }
  }
}
</script>

<style scoped>

</style>