<template>
  <v-card
      @click="navigateToUser(artist.id)"
      color="primary"
      class="wall-item border-0"
      :style="{ height: size * 200 + 'px' }"
  >
    <v-img
        height="100%"
        width="100%"
        cover
        :src="'data:image/png;base64,' + artist.profilePicture"
        class="background-image"
    ></v-img>

    <div class="content-wrapper" :class="backgroundGradient">
      <div class="d-flex flex-column justify-end h-100">
        <span style="font-size: 1.5rem">
          <span v-html="artist.name"></span>
        </span>
        <span v-if="artist.role">{{ artist.role }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import Artist from "@/models/Artist";

export default {
  name: "ArtistKachel",
  props:{
    iframe:{
      type:Boolean,
      default:false
    },
    artist:Artist,
    size:1
  },
  computed:{
    backgroundGradient() {
      if (this.artist.role === "Artist") {
        return "artist-gradient";
      } else if (
          this.artist.role === "Speaker" ||
          this.artist.role === "Speaker & Artist"
      ) {
        return "speaker-gradient";
      } else {
        return "user-gradient";
      }
    },
  },
  methods:{
    navigateToUser(userId) {
      if(this.iframe){
        location.href = "/u/user/" + userId;
      }
      else location.href = "/user/" + userId;
    },
  }
}
</script>

<style scoped>
.wall-item {
  cursor: pointer;
  position: relative;
  width: 250px;
  height: 250px !important;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.wall-item:hover {
  transform: scale(1.02);
}

.me-btn {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 3;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.content-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
}
.artist-gradient {
  background: linear-gradient(to bottom, transparent 30%, #9747ff 150%);
}

.speaker-gradient {
  background: linear-gradient(to bottom, transparent 30%, #bdd600 150%);
}

.user-gradient {
  background: linear-gradient(to bottom, transparent 0%, #28202e 100%);
}

.user-title {
  font-size: 0.9em;
  opacity: 0.9;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.companies-list {
  display: block;
  font-size: 0.85em;
  opacity: 0.8;
  margin-top: 8px;
}

.box--double {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}

@media (max-width: 959px) {
  .wall-item {
    height: 250px !important;
    margin: 0;
  }

  .content-wrapper {
    padding: 12px;
  }

  .user-name {
    font-size: 1.2em !important;
  }

  /* Deaktiviere double-size für mobile */
  .box--double {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
    height: 250px !important;
    width: calc(50% - 8px) !important;
  }
}

/* Für sehr kleine Bildschirme (z.B. schmale Smartphones) */
@media (max-width: 480px) {
  .wall-item {
    height: 200px !important;
    width: 100% !important;
    margin: 0;
    transition: transform 0.3s ease;
  }

  .box--double {
    height: 200px !important;
    width: 100% !important;
  }
}
</style>