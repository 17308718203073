<template>
  <v-sheet
    height="200px"
    :style="{
      backgroundImage: `url(${
        user?.image
          ? 'data:image/webp;base64,' + user?.image
          : require('../../assets/Placeholder-Profile-819x1024.webp')
      })`,
    }"
    style="background-size: contain"
    class="bg-transparent text-white d-flex justify-center align-center pb-5 pt-10"
  >
  </v-sheet>
  <v-container class="mt-7">
    <v-list-item class="pa-0" v-if="loading">
      <template #prepend> </template>
      <template #title>
        <v-skeleton-loader
          style="font-size: 1.5rem; margin-bottom: "
          color="transparent"
          type="text"
          width="150"
        ></v-skeleton-loader>
      </template>
      <template #subtitle>
        <v-skeleton-loader
          type="text"
          width="200"
          color="transparent"
        ></v-skeleton-loader>
        <v-chip-group class="d-flex flex-nowrap">
          <v-skeleton-loader
            width="100px"
            type="chip"
            color="transparent"
            class="mr-1"
          ></v-skeleton-loader>
          <v-skeleton-loader
            width="100px"
            type="chip"
            color="transparent"
            class="mr-1"
          ></v-skeleton-loader>
          <v-skeleton-loader
            width="100px"
            type="chip"
            color="transparent"
          ></v-skeleton-loader>
        </v-chip-group>
      </template>
    </v-list-item>
    <v-list-item style="" v-else>
      <template #prepend> </template>

      <template #title>
        <div style="font-size: 3.5rem; margin-bottom: -1.8rem">
          <span
            v-if="user.firstName && user.lastName"
            v-text="user.firstName + ' ' + user.lastName"
          ></span>
          <span v-else-if="user.name" v-text="user.name"></span>
          <span v-else>Unnamed</span>
        </div>
        <br v-if="!$store.state.loading.loadingState" />
      </template>
      <span>
        <span v-if="professions[0]"
          ><span v-html="professions[0].role"></span>@<span
            v-if="professions[0].company"
            v-html="professions[0].company.name"
          ></span>
          <span
            v-if="user.socials.website"
            class="ml-2 text-decoration-underline text-accent"
            ><a :href="user.socials.website" target="_blank"
              ><v-icon class="mr-1" size="x-small">mdi-open-in-new</v-icon
              >Website
            </a>
          </span>
        </span>
      </span>
      <v-chip-group class="overflow-x-auto">
        <v-chip
          tile
          style="border-radius: 5px !important"
          color="white"
          :size="$vuetify.display.smAndDown ? 'small' : 'large'"
          @click="$router.push('/lobby/' + tag.id)"
          v-for="tag of user.tags"
          :key="tag.id"
        >
          {{ tag.name }}
        </v-chip>
      </v-chip-group>
    </v-list-item>
    <v-sheet
      v-if="$store.state.loading.loadingState"
      color="transparent"
      class="px-5 py-2 my-2 d-flex flex-nowrap justify-start"
    >
      <v-skeleton-loader
        class="little-avatar-loader"
        type="avatar"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="little-avatar-loader"
        type="avatar"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="little-avatar-loader"
        type="avatar"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="little-avatar-loader"
        type="avatar"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="little-avatar-loader"
        type="avatar"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="little-avatar-loader"
        type="avatar"
        color="transparent"
      ></v-skeleton-loader>
    </v-sheet>
    <v-sheet
      v-else
      color="transparent"
      class="px-5 pt-3 pt-md-0 mb-5 d-flex justify-space-between justify-md-start ga-md-2"
    >
      <v-btn
        v-if="user?.socials.spotify"
        :href="'https://open.spotify.com/artist/' + user?.socials.spotify"
        target="_blank"
        icon
        color="offwhite"
      >
        <v-icon>mdi-spotify</v-icon>
      </v-btn>
      <v-btn
        v-if="user?.socials.soundcloud"
        :href="'https://soundcloud.com/' + user?.socials.soundcloud"
        target="_blank"
        color="offwhite"
        icon
      >
        <v-icon>mdi-soundcloud</v-icon>
      </v-btn>
      <v-btn
        v-if="user?.socials.instagram"
        :href="'https://www.instagram.com/' + user?.socials.instagram"
        target="_blank"
        color="offwhite"
        icon
      >
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn
        v-if="user?.socials.youtube"
        :href="'https://www.youtube.com/' + user?.socials.youtube"
        target="_blank"
        color="offwhite"
        icon
      >
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn
        v-if="user?.socials.linkedIn"
        :href="'https://www.linkedin.com/in/' + user?.socials.linkedIn"
        target="_blank"
        color="offwhite"
        icon
      >
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn
        v-if="user?.socials.twitter"
        :href="'https://twitter.com/' + user?.socials.twitter"
        target="_blank"
        color="offwhite"
        icon
      >
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
    </v-sheet>
    <v-card v-if="loading && user?.bio" color="grey-darken-4" flat class="py-3 my-2 my-md-5 rounded-lg">
      <h3 class="px-5">Bio</h3>
      <v-skeleton-loader
        style="min-height: 100px"
        class="py-5"
        color="transparent"
        v-if="loading"
        type="paragraph"
      ></v-skeleton-loader>

      <p v-else style="min-height: 100px" class="pa-5">
        <span v-if="user?.bio" v-text="user?.bio"></span>
        <span v-else class="text-grey">Nothing here...</span>
      </p>
    </v-card>

    <v-card flat color="grey-darken-4" class="py-3 my-2 my-md-5 rounded-lg">
      <h3 class="px-5 mb-3">All References</h3>
      <v-skeleton-loader
        v-if="loading"
        width="200px"
        color="transparent"
        type="list-item-two-line@3"
      ></v-skeleton-loader>

      <div v-else>
        <v-list-item v-for="profession of professions" :key="profession.id">
          <template #title>
            <div v-if="profession.role && profession.company">
              <span>{{ profession.role }}</span>
            </div>
          </template>
          <template #subtitle>
            <span
              >@<span v-if="profession.company">{{
                profession.company.name
              }}</span></span
            >
          </template>
        </v-list-item>
      </div>
    </v-card>
    <v-sheet
      color="transparent"
      class="my-5"
      v-if="$store.state.loading.loadingState"
    >
      <v-row>
        <v-col cols="4" v-for="n in 3" :key="n">
          <v-card rounded="xl" color="transparent" flat>
            <v-skeleton-loader
              flat
              color="transparent"
              type="image"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

    <div flat class="my-5" v-else-if="user?.sameCompanyUsers.users.length > 0">
      <h3 class="mt-2">People working with {{ user.name }}</h3>
      <div class="rounded wrapper mt-3">
        <UserSheetWall
          v-for="(college, index) in user?.sameCompanyUsers.users"
          :size="$vuetify.display.smAndDown ? '0.5' : '1'"
          :index="index"
          :hover="hover"
          :user="college"
        />
      </div>
      <!-- <div class="d-flex justify-center">
        <v-btn variant="text" class="my-2" @click="loadMoreCompany"
          >Load more <v-icon>mdi-refresh</v-icon></v-btn
        >
      </div> -->
    </div>
    <div class="my-5 mx-5" v-if="usersWithSameRole.items.length > 0">
      <h3>
        Other
        <span
          v-for="(profession, index) of currentUser.professions"
          :key="profession?.id"
          ><span v-html="profession?.role"></span
          ><span v-if="index < currentUser.professions.length - 1">/</span>
        </span>
      </h3>
      <div class="rounded wrapper mt-5">
        <UserSheetWall size="0.5" :index="index" :hover="hover" :user="user" />
      </div>
    </div>
    <v-sheet
        v-if="!anonymous"
      color="grey-darken-4"
      flat
      class="py-3 px-5 my-2 my-md-5 rounded-lg"
    >
      <h3 class="mt-5">Posts</h3>
      <div
        v-if="$store.state.loading.loadingState"
        class="d-flex flex-column flex-md-row justify-space-around py-1 overflow-x-auto mb-8"
      >
        <v-skeleton-loader
          class="mx-2 mt-4"
          elevation="2"
          color="transparent"
          type="list-item-avatar, image, list-item-two-line"
          width="100%"
          height="350"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-2 mt-4"
          elevation="2"
          color="transparent"
          type="list-item-avatar, image, list-item-two-line"
          width="100%"
          height="350"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-2 mt-4"
          elevation="2"
          color="transparent"
          type="list-item-avatar, image, list-item-two-line"
          width="100%"
          height="350"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <FeedOfUser v-if="user?.id" inProfile :user-id="user?.id" />
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import ApiService from "../../services/api.service";
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import FeedOfUser from "@/components/feed/FeedOfUser";

export default {
  name: "UserDetails",
  data() {
    return {
      loading: false,
      user: null,
      professions: [],
      tags: [],

      usersWithSameRole: {
        items: [],
        count: 3,
      },
      // usersWithSameCompany: {
      //   items: [],
      //   count: 3,
      // },
    };
  },
  beforeRouteUpdate(to) {
    // called when the route that renders this component has changed, but this component is reused in the new route.
    // For example, given a route with params `/users/:id`, when we navigate between `/users/1` and `/users/2`,
    // the same `UserDetails` component instance will be reused, and this hook will be called when that happens.
    // Because the component is mounted while this happens, the navigation guard has access to `this` component instance.

    this.getUser();
    this.$emit("scrollTop");
  },
  computed: {
    anonymous() {
      return this.$route.meta.anonymous;
    },
    isArtist() {
      return this.currentUser.roles.includes("ROLE_ARTIST");
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  components: { FeedOfUser, UserSheetWall },
  props: {
    id: String,
  },
  async created() {
    await this.getUser();
    console.log("User", this.user);
    this.professions = this.user.professions;
    this.tags = this.user.tags;
  },
  methods: {
    updateUser() {
      let self = this;
      ApiService.post("user/update", this.currentUser).then((response) => {
        let user = JSON.parse(localStorage.getItem("user"));
        let merged = { ...user, ...response.data };
        this.$store.dispatch("auth/updateLocalUser", merged);
        self.$store.dispatch("loading/success");
      });
    },
    async getUser() {
      let self = this;
      this.loading = true;
      try {
        const response = await ApiService.get(
          "user/" + this.id + "/details/page/0"
        );
        self.user = response.data;
        self.$store.dispatch("loading/success");
      } catch (error) {
        console.error("Fehler beim Laden des Benutzers:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }
}

.wrapper > div {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
</style>
