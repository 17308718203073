<template>
<div class="d-flex justify-start w-100" style="gap:20px">
    <ArtistKachel :iframe="iframe" v-for="artist of artists" :key="artist.id" :artist="artist"/>

</div>
</template>

<script>
import ArtistKachel from "@/components/artist/ArtistKachel";
import UserSheetWall from "@/views/Lobby/UserSheetWall.vue";
export default {
  name: "ArtistList",
  components: {UserSheetWall, ArtistKachel},
  props:{
    iframe:{
      type:Boolean,
      default:false
    },
    artists:Array,
    public:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style scoped>

</style>